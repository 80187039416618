export enum DocumentType {
  IdCard = 'idcard',
  Passport = 'passport',
  ElectronicResidencyTitle = 'electronic_residency_title',
  DriversLicense = 'drivers_license',
  ResidencePermit = 'residence_permit',
}

export enum ContactType {
  Business = 'BUSINESS',
  Private = 'PRIVATE',
}

export enum IdentStatus {
  SharePending = 'SHARE_PENDING',
  Initialized = 'INITIALIZED',
  ReviewPending = 'REVIEW_PENDING',
  Success = 'SUCCESS',
  Timeout = 'TIMEOUT',
  Failed = 'FAILED',
  Canceled = 'CANCELED',
}

export enum SignStatus {
  ReviewPending = 'REVIEW_PENDING',
  Success = 'SUCCESS',
  Timeout = 'TIMEOUT',
  Failed = 'FAILED',
  Canceled = 'CANCELED',
  Aborted = 'ABORTED',
}

export enum Locales {
  en = 'en',
  de = 'de',
}

export enum VerificationStatus {
  Initialized = 'INITIALIZED',
  ReviewPending = 'REVIEW_PENDING',
  SharePending = 'SHARE_PENDING',
  Success = 'SUCCESS',
  Aborted = 'ABORTED',
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Timeout = 'TIMEOUT',
}

export const SignFailureStatuses = [SignStatus.Failed, SignStatus.Timeout, SignStatus.Canceled, SignStatus.Aborted];

export const VerifyFailureStatuses = [
  VerificationStatus.Aborted,
  VerificationStatus.Failed,
  VerificationStatus.Timeout,
  VerificationStatus.Canceled,
];

export const IdentFailureStatuses = [IdentStatus.Failed, IdentStatus.Timeout, IdentStatus.Canceled];

export enum DocumentVerificationType {
  AddressProof = 'ADDRESS_PROOF',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
}

export enum NorthDataCompanyStatus {
  Active = 'active',
  Terminated = 'terminated',
}

export const DEFAULT_DATE_FORMAT = 'DD.MM.YYYY';

export const DEFAULT_COUNTRY_CODE_NUMBER = '49';
export const API_PREFIX = '/dipp/api';
export const DEFAULT_LOADING_SPINNER_PATH = '/images/loading-general.gif';
export const DEFAULT_LOADING_SPINNER_ALT = 'loading';

export const DEFAULT_PAGE_PREFIX_TITLE = 'Verimi Business';

export const MAX_FILE_SIZE = 26214400;
export const OCR_FILE_FORMATS = ['image/png', 'image/jpeg'];

export const AUTOCOMPLETE_DEBOUNCE_TIME = 500;

// todo outsource to photos library
export enum PdFOutoutType {
  bloburi = 'bloburi',
  bloburl = 'bloburl',
  datauristring = 'datauristring',
  dataurlstring = 'dataurlstring',
  dataurl = 'dataurl',
  datauri = 'datauri',
}

export const PassportNumberValidationCountries = [
  'AM',
  'AR',
  'AT',
  'AU',
  'AZ',
  'BE',
  'BG',
  'BY',
  'BR',
  'CA',
  'CH',
  'CN',
  'CY',
  'CZ',
  'DE',
  'DK',
  'DZ',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IN',
  'IR',
  'ID',
  'IS',
  'IT',
  'JM',
  'JP',
  'KR',
  'KZ',
  'LI',
  'LT',
  'LU',
  'LV',
  'LY',
  'MT',
  'MX',
  'MY',
  'MZ',
  'NL',
  'NZ',
  'PH',
  'PK',
  'PL',
  'PT',
  'RO',
  'RU',
  'SE',
  'SL',
  'SK',
  'TH',
  'TR',
  'UA',
  'US',
  'ZA',
];

export const DEFAULT_COUNTRY_CODE = 'DE';

export enum PlatformType {
  Web = 'WEB',
}

export enum RegisterOption {
  SesIdent = 'SES_IDENT_DETAILS',
  VerimiAccount = 'VERIMI_ACCOUNT_DETAILS',
}

export enum SubProcessType {
  Ident = 'IDENT',
  Sign = 'SIGN',
  Verify = 'VERIFY',
}
