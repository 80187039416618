export const QUERIES = {
  CONSENT_LINKS_RETRIEVE: 'CONSENT_LINKS_RETRIEVE',
  STATUSES_RETRIEVE: 'STATUSES_RETRIEVE',
  COUNTRIES_LIST: 'COUNTRIES_LIST',
  SERVICE_PROVIDER_ID_RETRIEVE: 'SERVICE_PROVIDER_ID_RETRIEVE',
  REGISTER_OPTION_RETRIEVE: 'REGISTER_OPTION_RETRIEVE',
  USER_DETAILS_RETRIEVE: 'USER_DETAILS_RETRIEVE',
  ALLOWED_DOCUMENTS_RETRIEVE: 'ALLOWED_DOCUMENTS_RETRIEVE',
  OCR_DATA_RETRIEVE: 'OCR_DATA_RETRIEVE',
  LEGAL_PERSON_AUTOSUGGESTIONS_LIST: 'LEGAL_PERSON_AUTOSUGGESTIONS_LIST',
  LEGAL_PERSON_REPRESENTATIVE_DATA: 'LEGAL_PERSON_REPRESENTATIVE_DATA',
  SIGNIN_SERVICE_PROCESS_ID_RETRIEVE: 'SIGNIN_SERVICE_PROCESS_ID_RETRIEVE',
  REDIRECT_URL_RETRIEVE: 'REDIRECT_URL_RETRIEVE',
  PARTNER_LOGO_RETRIEVE: 'PARTNER_LOGO_RETRIEVE',
  SERVICE_PROVIDER_DATA_RETRIEVE: 'SERVICE_PROVIDER_DATA_RETRIEVE',
  IS_WAAS: 'IS_WAAS',
  WAAS_IDENT: 'WAAS_IDENT',
  COMPANY_DATA: 'COMPANY_DATA',
  VERIFY_REDIRECT_URL: 'VERIFY_REDIRECT_URL',
};
